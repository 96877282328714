import React from "react";
import Heading from "src/components/Heading";

const ContentHeadingCentered = (props) => {
  return (
    <div className="relative mb-12 sm:mb-24 max-h-[90vh] overflow-hidden">
      <img
        className="block w-full md:hidden aspect-[1/1] object-cover"
        src={props.content.featured_image.filename}
      />
      <img
        className="hidden w-full md:block aspect-[16/9] object-cover"
        src={props.content.featured_image_wide.filename}
      />
      <div className="bg-cyan relative sm:absolute bottom-0 w-full opacity-90">
        <div className="relative w-3/4 py-12 px-6 sm:px-12">
          <Heading
            size="h1"
            className="!font-poppins !text-3xl font-normal text-white"
          >
            {props.name}
          </Heading>
          <p className="text-lg text-white">{props.content.hash_tag}</p>
        </div>
      </div>
    </div>
  );
};

export default ContentHeadingCentered;
