import React from 'react'
import Page from '../components/Page'
import Layout from "../components/Layout"
import StoryblokService from '../utils/storyblok-service'
import { PageContext } from "src/utils/context";
import ContentHeadingCentered from "src/components/TBB/ContentHeadingCentered"

export default class Editor extends React.Component {
  state = {
    story: null
  }
 
  async getInitialStory() {
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get('path')    
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${path}`, {version: 'draft'})
    return story
  }
 
  async componentDidMount() {
    let story = await this.getInitialStory()
    if(story.content) this.setState({ story })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }
 
  render() {
    if(this.state.story === null) {
      return (<div>Loading</div>)
    }
    const toggleContentHeadingCentered = ["client"].includes(this.state.story.content.component)
   
    return (
      <PageContext.Provider
      value={{
        hasHero:
            this.state.story.content.body && ["hero", "hero-custom"].includes(this.state.story.content.body[0]?.component) ? true
            : toggleContentHeadingCentered ? true
            : false,
        currentPage: this.state.story.uuid,
        breadcrumb: this.props.pageContext.breadcrumb,
        location: this.props.location,
        name: this.state.story.name,
        first_published_at: this.state.story.first_published_at,
        content: this.state.story.content,
        setCtaReferrer: this.setCtaReferrer,
        ctaReferrer: this.state.cta_referrer,
        tag_list: this.state.story.tag_list
      }}
    >
     <Layout location={this.props.location}>
     {toggleContentHeadingCentered && 
        <ContentHeadingCentered {...this.state.story}/>
      }
      <Page blok={this.state.story.content} />
    </Layout>
    </PageContext.Provider>
    )
  }
}